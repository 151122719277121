body {
  font-family: 'Work Sans', sans-serif;
}

/* Image wrapper for native HTML button but mutes all native styles */
.button-img-wrapper {
  border: none;
  background-color: inherit;
}
.button-img-wrapper:focus {
  border: none;
  outline: none;
}

/* Tree Component Overwrites -- remove tree expand icon */
.ant-tree li span.ant-tree-switcher {
  display: none;
}
.tree-checkbox-filter .ant-tree li span.ant-tree-switcher {
  display: inline-block;
}
.tree-checkbox-filter {
  min-width: 15%;
}
@media screen and (min-width: 1024px) {
  .tree-checkbox-filter {
    max-width: 35%;
  }
}
@media screen and (min-width: 1200px) {
  .tree-checkbox-filter {
    max-width: 25%;
  }
}
.tree-checkbox-filter .ant-select-selection {
  border: 1px solid #3b86ff;
}
