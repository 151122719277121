/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Top nav menu */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-top-nav-menu {
  background-color: inherit;
  border: none;
}

.v2-top-nav-menu > .ant-menu > .ant-menu-item > a > span,
.v2-top-nav-menu > .ant-menu-submenu > .ant-menu-submenu-title > span {
  color: white;
  opacity: 0.65;
}

.v2-top-nav-menu > .ant-menu-item,
.v2-top-nav-menu > .ant-menu-submenu {
  background-color: inherit;
  padding: 0 16px;
  height: 64px;
  line-height: 64px;
  border-color: #001529 !important;
}
/* Dropdown menu (User logout) */
.v2-user-dropdown-menu {
  background: #001529;
  margin-top: 20px;
}

/* Submenu */
.v2-top-nav-menu > .ant-menu-vertical > .ant-menu-item-selected,
.v2-top-nav-menu > .ant-menu-vertical {
  background-color: #001529;
}

/* Submenu - selected */
.v2-top-nav-menu > .ant-menu-vertical > .ant-menu-item-selected {
  background-color: inherit;
}

/* Normal state */
.v2-user-dropdown-menu > .ant-dropdown-menu-item,
.v2-top-nav-menu > .ant-menu-item > a > span {
  color: white;
  opacity: 0.65;
}

/* Active */
.v2-user-dropdown-menu > .ant-dropdown-menu-item:hover,
.v2-top-nav-menu > .ant-menu-vertical > .ant-menu-item-active > a > span,
.v2-top-nav-menu > .ant-menu-submenu-active > div > span,
.v2-top-nav-menu > .ant-menu-item-active > a > span {
  background-color: inherit;
  opacity: 1 !important;
}

/* Selected */
.v2-top-nav-menu > .ant-menu-item-selected {
  border-color: #69e4a6 !important;
}

.v2-top-nav-menu > .ant-menu-submenu-selected > div > span,
.v2-top-nav-menu > .ant-menu-vertical > .ant-menu-item-selected > a > span,
.v2-top-nav-menu > .ant-menu-item-selected > a > span {
  color: #69e4a6 !important;
  opacity: 1 !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Tab panels (SR-detail page) */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-tab-wrapper .ant-tabs-ink-bar {
  background-color: #69e4a6 !important;
}

.v2-tab-wrapper .ant-tabs-tab-active {
  color: #69e4a6;
  font-weight: 600;
}

.v2-tab-wrapper .ant-tabs-tab:hover {
  color: #69e4a6;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Primary buttons and Link */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-btn-v2-primary:focus,
.ant-btn-v2-primary:hover,
.ant-btn-v2-primary {
  background-color: #69e4a6;
  border-color: #5ecc95;
  color: white;
  font-weight: 600;
}
.ant-btn-v2-primary:focus,
.ant-btn-v2-primary:hover {
  color: white;
  border-color: #69e4a6;
}

.ant-btn-v2-secondary:hover {
  border-color: inherit;
}

.ant-link-v2-with-underline:hover {
  text-decoration: underline;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Popup Confirm */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.popup-v2-wrapper .ant-popover-inner-content {
  padding: 32px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Disabled table row for AntD's table
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.disable-table-row {
  background-color: #fafafa;
  cursor: not-allowed;
}

.disable-table-row .ant-table-selection-column > span {
  visibility: hidden;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Radio buttons
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-radio-wrapper .ant-radio-button-wrapper-checked:hover,
.v2-radio-wrapper .ant-radio-button-wrapper-checked {
  color: white !important;
  background-color: #69e4a6;
  border-color: #5ecc95 !important;
}

.v2-radio-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #69e4a6;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Avatar
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-avatar-wrapper > img {
  object-fit: cover;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Select Dropdown
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-select-wrapper .ant-select-dropdown,
.v2-select-wrapper > div > div,
.v2-select-wrapper > div {
  position: relative !important;
}
.v2-shifts-week-select > .ant-select-selection {
  border: none;
  margin-right: 8px;
}
.v2-shifts-week-select
  > .ant-select-selection
  > .ant-select-selection__rendered
  > .ant-select-selection-selected-value {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
}

/* TODO: remove this once FilterWithSearch component is refactroed out as helpster-components library */
/* for Worker Profile page filter dropdown */
.filter-dropdown-wrapper > div {
  position: relative;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Checkable Tag */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-checkable-tag-wrapper > .ant-tag-checkable:hover {
  cursor: pointer;
}
.v2-checkable-tag-wrapper > .ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: white;
  background-color: #69e4a6 !important;
  border-color: #5ecc95 !important;
  font-weight: bold;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* FAQ List (SR Form) */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-ul-wrapper ul {
  padding-left: 20px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Typography */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-typography {
  font-family: 'Work Sans', sans-serif;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Cards*/
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
#id-detail-card > div > div > div.ant-card-head-title {
  padding: 0px;
}

#address-card > div > div > div.ant-card-head-title {
  padding: 0px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Upload */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.broadcast-image-uploader,
.broadcast-image-uploader > .ant-upload-select-picture-card {
  width: 100%;
  height: 322px;
}
.broadcast-image-uploader > .ant-upload-select-picture-card > .ant-upload {
  height: inherit;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Steps */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-steps-wrapper > .ant-steps-item-process .ant-steps-item-icon {
  background: #69e4a6;
  border-color: #69e4a6;
}

.v2-steps-wrapper > .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #69e4a6;
  color: #69e4a6;
}

.v2-steps-wrapper > .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #69e4a6;
}

.v2-steps-wrapper > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #69e4a6;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Layout header */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-layout-header {
  height: 64px;
  padding: 0 20px;
  line-height: 64px;
  background: #001529;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Antd table row hover */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.schedules-list-table
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: unset;
}
/* Form */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-shifts-form .ant-form-item-label {
  line-height: initial;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Antd spinner */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.total-workforce-card-spinner > .ant-spin-dot > .ant-spin-dot-item {
  background-color: white;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Antd select */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.workforce-client-select
  > .ant-select-selection
  > .ant-select-selection__rendered
  > .ant-select-selection__placeholder {
  font-weight: 600;
  font-size: 18px;
}

.workforce-client-select > .ant-select-selection > .ant-select-selection__rendered {
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Antd modal*/
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-verify-covid-certification-modal .ant-modal-footer {
  display: flex;
}

.no-border {
  border: none !important;
}
